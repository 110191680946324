import { useState } from 'react';
import storage from '../../../util/storage';
import Create from '../../../../useCase/Prospect/Create';
import FetchProspectGateway from '../../../../gateway/Prospect/FetchProspectGateway';
import Authorize from '../../../../useCase/Client/Authorize';
import FetchClientGateway from '../../../../gateway/Client/FetchClientGateway';
import Client from '../../../../domain/Client/Client';
import {Folder} from '../../../../domain/Folder/Folder';

interface UseProspectReturn {
  isLoading: boolean;
  isVisibleCreateProspect: boolean;
  isVisibleAuthorizeClient: boolean;
  success: string | null;
  errors: Error[] | null;
  handleClick: (action: string) => void;
  readOnlyMode: boolean;
}

const useProspect = (): UseProspectReturn => {
  const clientId = storage.getClientId()
  const dossierId = storage.getFolderId()

  const [isLoading, setLoading] = useState<boolean>(false)
  const [isVisibleCreateProspect, setVisibleCreateProspect] = useState<boolean>(clientId === null)
  const [isVisibleAuthorizeClient, setVisibleAuthorizeClient] = useState<boolean>(clientId !== null)
  const [success, setSuccess] = useState<string|null>(null)
  const [errors, setErrors] = useState<Error[]|null>(null)
  const readOnlyMode = 'back_office_user' == localStorage.getItem('role');

  const handleClick = (action: string) => {
    if (action === 'create' && dossierId !== null) {
      setLoading(true)
      new Create(new FetchProspectGateway()).execute(dossierId).then(response => {
        if (response instanceof Client) {
          setVisibleCreateProspect(false)

          storage.setClientId(response.id)

          new Authorize(new FetchClientGateway()).execute(dossierId, response.id).then(responseAuthorize => {
            if (responseAuthorize instanceof Folder) {
              storage.setAuthorizedClients(responseAuthorize.authorizedClients)
              setLoading(false)
              setSuccess('common.success-prospect')
            }
          })
        } else {
          setErrors(response)
          setLoading(false)
        }
      }).catch((error) => {
        if (error.response && error.response.data && Array.isArray(error.response.data.errors)) {
          setErrors(error.response.data.errors)
        } else {
          setErrors([{message: 'create-prospect', name: 'create-prospect'}])
        }
        setLoading(false)
      })

      return false
    }

    if (clientId !== null && dossierId !== null) {
      setLoading(true)
      new Authorize(new FetchClientGateway()).execute(dossierId, clientId).then(response => {
        if (response instanceof Folder) {
          setVisibleAuthorizeClient(false)
          storage.setAuthorizedClients(response.authorizedClients)
          setSuccess('common.success-client')
        } else {
          setErrors(response)
        }

        setLoading(false)
      }).catch(() => {
        setVisibleAuthorizeClient(true)
      })
    }
  }

  return {
    isLoading,
    isVisibleCreateProspect,
    isVisibleAuthorizeClient,
    success,
    errors,
    handleClick,
    readOnlyMode
  }
}

export default useProspect;
