import React, { FunctionComponent, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import FolderComponent from "./FolderComponent"
import FetchFolderGateway from "../../../gateway/Folder/FetchFolderGateway"
import Loader from '../loader/Loader'
import { FolderDetail } from "../../../domain/Folder/Folder"
import { Pagination } from '../pagination'
import { useQuery } from '../../../infrastructure/hooks'

type Props = {
  term: string,
  state: string | null
}

const ITEMS_PER_PAGE = 10;

const FoldersComponent: FunctionComponent<Props> = ({ term, state }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [folders, setFolders] = useState<FolderDetail[] | null>(null);
  const [totalItems, setTotalItems] = useState<number>(0);

  const query = useQuery();
  const history = useHistory();
  const currentPage = query.get('currentPage') || '1';
  const currentPageNumber = parseInt(currentPage, 10);

  const updateQueryParams = (params: { [key: string]: string }) => {
    const searchParams = new URLSearchParams(query);
    Object.keys(params).forEach(key => searchParams.set(key, params[key]));
    history.push({ search: searchParams.toString() });
  };

  useEffect(() => {
    const fetchFolders = async () => {
      setIsLoaded(false);
      const folderGateway = new FetchFolderGateway();
      const fetchedFolders = await folderGateway.getFolders(currentPageNumber, ITEMS_PER_PAGE, state, term);
      if (fetchedFolders.dossiers === null) {
        return;
      }

      setFolders(fetchedFolders.dossiers);
      setTotalItems(fetchedFolders.totalItems);
      setIsLoaded(true);
    };

    fetchFolders();
  }, [term, state, currentPageNumber]);

  useEffect(() => {
    updateQueryParams({ currentPage: '1' });
  }, [term]);

  const handleDeleteFolder = (id: string): void => {
    if (!folders) return;
    setFolders(folders.filter(folder => folder.id !== id));
  };

  if (!isLoaded) {
    return <div className="flex-container">
      <div className="col-md-12">
        <Loader />
      </div>
    </div>
  }

  return (
    <div className="flex-container">
      <div className="col-md-12">
        {folders?.map((folder) => (
          <FolderComponent FolderDetail={folder} key={folder.id} handleDeleteFolder={handleDeleteFolder} />
        ))}
        <Pagination itemsPerPage={ITEMS_PER_PAGE} totalItems={totalItems} />
      </div>
    </div>
  );
};

export default FoldersComponent;
