import React, {FunctionComponent, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import HeaderLightComponent from '../component/header/HeaderLightComponent';
import FooterComponent from '../component/footer/FooterComponent';
import storage from '../util/storage';
import CreateUseCase from '../../useCase/Folder/CreateUseCase';
import FetchFolderGateway from '../../gateway/Folder/FetchFolderGateway';

import SignedFiles from '../component/svg/SignedFiles';
import SignatureFiles from '../component/svg/SignatureFiles';
import CurrentCases from '../component/svg/CurrentCases';
import AbandonedFiles from '../component/svg/AbandonedFiles';

import '../../assets/styles/components/_dashboard.scss'
import envVariable from '../util/envVariable'

const Dashboard: FunctionComponent = () => {
  const {t} = useTranslation()
  const history = useHistory()

  const [role] = useState<string>(localStorage.getItem('role') ?? '');
  const [signedFiles, setSignedFiles] = useState<number>(0);
  const [signatureFiles, setSignatureFiles] = useState<number>(0);
  const [currentCases, setCurrentCases] = useState<number>(0);
  const [abandonedFiles, setAbandonedFiles] = useState<number>(0);

  useEffect(() => {
    (new FetchFolderGateway()).getFolders(1, 10000, null, null).then(folders => {
        if (null !== folders.dossiers) {
          setSignedFiles((folders.dossiers.filter(folder => folder.status === 'signed')).length)
          setSignatureFiles((folders.dossiers.filter(folder => folder.status === 'signature')).length)
          setCurrentCases((folders.dossiers.filter(folder => (folder.status === 'ongoing' || folder.status === 'mission_validated' || folder.status === 'mission_validation' || folder.status === 'adequacy_report_validated' || folder.status === 'adequacy_report_validation'))).length)
          setAbandonedFiles((folders.dossiers.filter(folder => (folder.status === 'canceled' || folder.status === 'rejected'))).length)
        }
      }
    )
  }, [])

  const createUseCase = new CreateUseCase(new FetchFolderGateway());

  function createFileWithoutClient() {
    createUseCase.execute(storage.getFormId(), null)
      .then(() => {
        history.push(`/form/`)
      })
  }

  return <>
    <HeaderLightComponent />
    <>
      <div className="wrap u-mxAuto">
        <div className="flex-container">
          <div className="col-md-12">
            <h2 className="h3 help-wrapper u-mbs">{t('dashboard.title')}</h2>
          </div>
        </div>
      </div>
      <div className="min-height-content">
        <div className="box-elevations box-elevations--base box-elevations--max-heigt-initial box-elevations--mb u-mxAuto">
          {((role !== 'client' && role !== 'prospect_direct' && role !== 'back_office_user') || (role == 'prospect_direct')) &&
            <div className={`h4 help-wrapper u-mbs title-create-folder`}>{t('folder.create-for')}</div>
          }
          <div className="flex-container heading-folder-cta">
            {role !== 'client' && role !== 'prospect_direct' && role !== 'back_office_user' &&
              <>
                <div className="col-md-6">
                  <div className="button__container">
                    <Link to={'/creer-un-dossier-client'}
                          className="create-folder button button-primary button--large button--width-full">
                      {t(envVariable('REACT_APP_VARIABLES_CREATE_FOLDER_CUSTOMER_BUTTON', 'folder.create-customer'))}
                    </Link>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="button__container">
                    <a href="#" onClick={() => createFileWithoutClient()}
                       className="create-folder-prospect button button-primary button--large button--width-full">
                      {t(envVariable('REACT_APP_VARIABLES_CREATE_FOLDER_LEAD_BUTTON', 'folder.create-lead'))}
                    </a>
                  </div>
                </div>
              </>
            }
            {role == 'prospect_direct' &&
              <>
                <div className="col-md-12">
                  <div className="button__container">
                    <a href="#" onClick={() => createFileWithoutClient()}
                       className="create-folder-prospect button button-primary button--large button--width-full">{t('folder.create-folder')}</a>
                  </div>
                </div>
              </>
            }
          </div>
          <div className="dashboard__filters">
            <Link to={`/dossier/signed-files`} className="dashboard__filter">
              <div className="filter__icon"><SignedFiles color="var(--ink-1)"/></div>
              <div className="filter__number">{signedFiles}</div>
              <div className="filter__label" dangerouslySetInnerHTML={{__html: t('dashboard.signed-files')}}/>
            </Link>
            <Link to={`/dossier/signature-files`} className="dashboard__filter">
              <div className="filter__icon"><SignatureFiles color="var(--ink-1)"/></div>
              <div className="filter__number">{signatureFiles}</div>
              <div className="filter__label" dangerouslySetInnerHTML={{__html: t('dashboard.signature-files')}}/>
            </Link>
            <Link to={`/dossier/current-cases`} className="dashboard__filter">
              <div className="filter__icon"><CurrentCases color="var(--ink-1)"/></div>
              <div className="filter__number">{currentCases}</div>
              <div className="filter__label" dangerouslySetInnerHTML={{__html: t('dashboard.current-cases')}}/>
            </Link>
            <Link to={`/dossier/abandoned-files`} className="dashboard__filter">
              <div className="filter__icon"><AbandonedFiles color="var(--ink-1)"/></div>
              <div className="filter__number">{abandonedFiles}</div>
              <div className="filter__label" dangerouslySetInnerHTML={{__html: t('dashboard.abandoned-files')}}/>
            </Link>
          </div>
        </div>
      </div>
      </>
    <FooterComponent />
  </>
}

export default Dashboard;
