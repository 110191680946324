import React, {FunctionComponent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {RouteComponentProps} from 'react-router-dom';
import {Link} from 'react-router-dom';

import FoldersComponent from "../component/folder/FoldersComponent";
import FooterComponent from "../component/footer/FooterComponent";
import HeaderLightComponent from "../component/header/HeaderLightComponent";

import SignedFiles from '../component/svg/SignedFiles';
import SignatureFiles from '../component/svg/SignatureFiles';
import CurrentCases from '../component/svg/CurrentCases';
import AbandonedFiles from '../component/svg/AbandonedFiles';
import Back from '../component/svg/Back';

type Params = {
  state: string
  currentPage: string
};

const Folder: FunctionComponent<RouteComponentProps<Params>> = ({match}) => {
  const { t } = useTranslation()

  const [term, setTerm] = useState<string>('');

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTerm((e.target.value).toLowerCase());
  }

  const title = match?.params?.state !== undefined ? t(`dashboard.${match.params.state}`) : t('common.folders')

  return (
    <div>
      <HeaderLightComponent />
          <>
            <div className="wrap u-mxAuto">
              <div className="flex-container">
                <div className="col-md-12">
                  <Link to="/tableau-de-bord">
                    <Back color="var(--ink-1)" />
                    <span className="u-txt-size-s u-txt-color-ink-1 u-mxs">{t('button.back')}</span>
                  </Link>
                </div>
              </div>
              <div className="flex-container header-step-button-container">
                <div className="col-md-3">
                  <Link to={`/dossier/signed-files`}
                        className={`button button-primary button--large button--width-full button--files-state ${match?.params?.state === 'signed-files' ? 'button--files-state--active' : ''}`}>
                    <div dangerouslySetInnerHTML={{__html: t('dashboard.signed-files')}}/>
                    <SignedFiles color="var(--light)"/>
                  </Link>
                </div>
                <div className="col-md-3">
                  <Link to={`/dossier/signature-files`}
                        className={`button button-primary button--large button--width-full button--files-state ${match?.params?.state === 'signature-files' ? 'button--files-state--active' : ''}`}>
                    <div dangerouslySetInnerHTML={{__html: t('dashboard.signature-files')}}/>
                    <SignatureFiles color="var(--light)"/>
                  </Link>
                </div>
                <div className="col-md-3">
                  <Link to={`/dossier/current-cases`}
                        className={`button button-primary button--large button--width-full button--files-state ${match?.params?.state === 'current-cases' ? 'button--files-state--active' : ''}`}>
                    <div dangerouslySetInnerHTML={{__html: t('dashboard.current-cases')}}/>
                    <CurrentCases color="var(--light)"/>
                  </Link>
                </div>
                <div className="col-md-3">
                  <Link to={`/dossier/abandoned-files`}
                        className={`button button-primary button--large button--width-full button--files-state ${match?.params?.state === 'abandoned-files' ? 'button--files-state--active' : ''}`}>
                    <div dangerouslySetInnerHTML={{__html: t('dashboard.abandoned-files')}}/>
                    <AbandonedFiles color="var(--light)"/>
                  </Link>
                </div>
              </div>
            </div>
            <div className="wrap u-mxAuto">
              <div className="flex-container">
                <div className="col-md-8">
                  <h2 className="h3 u-mbs" dangerouslySetInnerHTML={{__html: title}}/>
                </div>
                <div className="col-md-4">
                  <input type="text" onChange={e => handleSearchInputChange(e)} style={{boxShadow: `0px 5px 8px rgb(0 0 0 / 10%)`}} placeholder={t('common.search')} className="button-search" />
                </div>
              </div>
            </div>
            <div className="min-height-content-folder">
              <div className="box-elevations box-elevations--base box-elevations--max-heigt-initial box-elevations--mb u-mxAuto">
                <FoldersComponent term={term} state={match?.params?.state} />
              </div>
            </div>
          </>
      <FooterComponent />
    </div>
  );
}

export default Folder;
